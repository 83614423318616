<template>
  <div class="tinymce-editor">
    <editor v-model="content" :init="init"></editor>
  </div>
</template>
<script>
import { uploadPic } from "@/model/modules/user"; // 接口

import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons'
// 编辑器插件plugins
import 'tinymce/plugins/image'// 图片
import 'tinymce/plugins/media'// 视频
import 'tinymce/plugins/preview'// 预览
import 'tinymce/plugins/code'// 源码
import 'tinymce/plugins/fullscreen'// 全屏
import 'tinymce/plugins/link'// 链接

export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: '',
      init: {
        language_url: location.origin + location.pathname + 'tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: location.origin + location.pathname + 'tinymce/skins/ui/oxide',
        content_css: '',
        height: 300,//编辑器高度
        plugins: 'preview fullscreen image link media code',
        toolbar: [
          'undo redo | cut copy paste | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | blockquote subscript superscript removeformat styleselect fontselect fontsizeselect |image media preview fullscreen | lineheight code'
        ],
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        menubar: false,
        branding: false,
        // 自定义上传图片
        images_upload_handler: (blobInfo, success, failure) => {
          let formdata = new FormData();
          formdata.append(blobInfo.blob().name, blobInfo.blob());
          formdata.append("object", "product");
          // formdata.append("imgData", blobInfo.base64());
          // formdata.append("name", blobInfo.blob().name);
          // formdata.append("size", blobInfo.blob().size);
          // formdata.append("type", blobInfo.blob().type);

          // success('https://www.baidu.com/img/bd_logo1.png');
          uploadPic(formdata).then(res => {
            success(res.data.body[0].filepath);
          });
        },
      },
      content: this.value
    }
  },
  watch: {
    content(newValue) {
      // 发出事件 v-model
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.content = newValue;
    }
  }
}

</script>

<style>
.tox-tinymce-aux {
  z-index: 9001 !important;
}
</style>
